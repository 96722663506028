(function () {
  'use strict';
  
  /*----------------------------------------------------*/
  /*  Header Fixed
  /*----------------------------------------------------*/

  function onFixed(BF) {
      BF.addClass('is-fixed');
      $('.fixed-phone').addClass('col-md-12');   
  }

  function offFixed(BF) {
      BF.removeClass('is-fixed');
      $('.fixed-phone').removeClass('col-md-12');
  }

  function blockFixed(BF) {
      var $t = $(".block-fixed");
      var fixedHeight = $(".wpar-fixed").children(".block-fixed").outerHeight(),
          fixedTop = $t.offset().top,
          topScroll = fixedTop + fixedHeight;

      $(".wpar-fixed").height(fixedHeight);

      $(window).on('scroll resize', function () {
          if ($(this).scrollTop() > topScroll) {
              onFixed($t);
          } else if ($(this).scrollTop() < 20) {
              offFixed($t);
          }
      });
  }

  if ($('*').is('.block-fixed')) {
      $('.block-fixed').wrapAll('<div class="wpar-fixed"></div>');
      blockFixed();
      $(window).on('resize', blockFixed);
  }

  /*----------------------------------------------------*/
  /*  Header Clone
  /*----------------------------------------------------*/

  $(document).ready(function () {
      var hMain = $('.header:not(.is-clone)'),
          hClone = $('.header.is-clone'),
          hMainHeight = hMain.innerHeight(),
          topHeight = hMain.offset().top + hMainHeight,
          hCloneHeight = hClone.innerHeight(),
          hAll = Number(hMainHeight + hCloneHeight);
      //clone = $('.block-fixed').before($('.block-fixed').clone().addClass("clone"));
      $('.header.is-clone').css({
          "top": "-" + hAll + "px"
      });
      $(window).scroll(function () {
          if ($(this).scrollTop() > topHeight) {
              $('.header.is-clone').addClass('is-fixed');
          } else {
              $('.header.is-clone').removeClass('is-fixed');
              $('.header.is-clone').css({
                  "top": "-" + hAll + "px"
              });
          }
      });
  });

/*----------------------------------------------------*/
/*	Scroll Anchor
/*----------------------------------------------------*/

$(document).ready(function() {
  var maskClick = "#anchor-";//"#anchor-";
      $('[href*="' + maskClick + '"]').on('click', function() {
           var itemId = $(this).attr("href"),
      strName = itemId.replace('#',''),
      itemName = '[name=' + strName + ']';
    if ( $('*').is(itemId) || $('*').is(itemName) ) {
      //console.log(itemName);
      var item = $('*').is(itemId) ? itemId : itemName,
              itemTop = $(item).offset().top,
              blockFixed = $(document).width() >= 768 ? ".header-clone" : ".header-mobile",
      heightHeader = $(blockFixed).outerHeight(),
              destination = itemTop - heightHeader;
              console.log(blockFixed + ' ' + heightHeader);
              
      $("html:not(:animated),body:not(:animated)").animate({scrollTop: destination}, 800);
    }
          return false;
      });
});
  /* */


  /*-----------------------Mobile menu slide--------------- */
        $('[data-control="menu-burger"]').on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
            var menu = $('.header-mobile--mobile');
            menu.slideToggle();
            $('.header-mobile').toggleClass('active');
        });
  /*--------------------------------------------------------*/

  /*-----------------------------script for hide product----------------------------------------*/
  $('.c-hidden__btn').on('click', function(e){
      e.preventDefault();
      $('.c-hidden').slideToggle();
      $('.c-hidden__btn').toggleClass('c-hidden__btn--active');
      // $('.c-hidden__btn').text('Скрыть');
  });
  /*--------------------------------------------------------------------------------------------*/

  /*----------------------Script for modal-------------------------------*/
      $(function () {
          var modal = $('.c-modal__container');
          var close = $('.c-form-message__item--close');

          close.on('click', function (e){
              e.preventDefault();
              modal.fadeOut(300);
          });
      });
  /*---------------------------------------------------------------------*/

  /*----------------------Script for btn up scroll-------------------------------*/

      $(function () {
          var scrollBtn = $('.btn-scroll');
          scrollBtn.on('click', function(e) {
              e.preventDefault();
              $("html:not(:animated),body:not(:animated)").animate({scrollTop: 0}, 800);
          });


      });

  /*---------------------------------------------------------------------*/

// ------Блок с выбором табов-----------------------------------
$(function(){
  var btn = $('.c-tab__link');
  var content = $('.c-tab__wrapper');
  btn.on('click', function(e){
      e.preventDefault();
      let _this = $(this);
      btn.removeClass('active');
      var count = $(this).data('slide');
      function showTab(content, dataTab){
          content.each(function(array, mas){
              $(this).css({opacity: 0.6}, 500);
              if(dataTab === array){
                _this.addClass('active');
                  $(this).animate({opacity: 1}, 1000);
                  $(this).css({display: 'block'});
              }else{
                  $(this).css({display: 'none'});
                  $(this).animate({opacity: 0.25}, 500);
              }
          });
      }
      showTab(content, count);
  });

});
// ---------------------------------------------------



/*--------------------Spoiler-------------------------------------------------*/

$(function () { 
  var accordeonTitle = $('.spoiler__title');
  var accordeonContent = $('.spoiler__content');
  accordeonTitle.on('click', function(e){
    e.preventDefault();
    $(this).next(accordeonContent).slideToggle();
    $(this).toggleClass('active');
 });
});

/*---------------------------------------------------------------------*/

$(function () { 
  var flag = true;
  $(window).scroll(function () {
      if (flag) {
          $('.count-span').each(function () {
              $(this).prop('Counter', 0).animate({
                  Counter: $(this).text()
              }, {
                      duration: 5000,
                      easing: 'swing',
                      step: function (now) {
                          $(this).text(Math.ceil(now));
                      }
                  });
          });
          flag = false;
      }
  });
});


$('.serts-carousel').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
    ],
});



// ------ Блок с продуктами -----------------------------------
$(function(){
    var btn = $('.product__btn');
    var block = $('.flip-card');

    btn.on('click', function(e){
        
        let _this = $(this);
        let parent =  _this.closest('.flip-card');

        parent.find('.front').css( "display", "none" ).animate({opacity: 1}, 1000);
        parent.find('.back').css( "display", "block" ).animate({opacity: 1}, 1000);

        $('.back-cancel').on('click', function(e){
            parent.find('.front').css( "display", "block" );
            parent.find('.back').css( "display", "none" );
        });

        

        // var count = $(this).data('slide');
        // function showTab(content, dataTab){
        //     content.each(function(array, mas){
        //         $(this).css({opacity: 0.6}, 500);
        //         if(dataTab === array){
        //           _this.addClass('active');
        //             $(this).animate({opacity: 1}, 1000);
        //             $(this).css({display: 'block'});
        //         }else{
        //             $(this).css({display: 'none'});
        //             $(this).animate({opacity: 0.25}, 500);
        //         }
        //     });
        // }
        // showTab(content, count);
    });
  
  });
  // ---------------------------------------------------


document.addEventListener('DOMContentLoaded', function () {
    'use strict';

    /* --------------------------------------------------------------*/
    /* Функция для прокрутки с контролем скорости
    /* --------------------------------------------------------------*/
    function scrollTo(to, duration = 700) {
        const element = document.scrollingElement || document.documentElement,
        start = element.scrollTop,
        change = to - start,
        startDate = +new Date(),

        easeInOutQuad = function (t, b, c, d) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        },
        animateScroll = function () {
            const currentDate = +new Date();
            const currentTime = currentDate - startDate;
            element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
            if (currentTime < duration) {
                requestAnimationFrame(animateScroll);
            }
            else {
                element.scrollTop = to;
            }
        };
        animateScroll();
    }

    const product = document.querySelectorAll('.product__item'); 
    const productColors = document.querySelectorAll('.product-color__item'); 

    productColors.forEach((productColor) => {

        productColor.addEventListener('click', () => {

            let product = productColor.closest('.product__item');
            let productImages = product.querySelector('.product__img');

            let productWebp = productImages.querySelector('source[type="image/webp"]');
            let productJpg = productImages.querySelector('source[type="image/jpg"]');
            let productImg = productImages.querySelector('img');

            removeClass(product);
            productColor.classList.add('color-active');
            
            productImg.setAttribute("src", 'img/'+ productColor.dataset.images + '.jpg');
            productJpg.setAttribute("srcset", 'img/'+ productColor.dataset.images + '.jpg');
            productWebp.setAttribute("srcset", 'img/webp/'+ productColor.dataset.images + '.webp');
        });

    });

    const removeClass = (product) =>{
        const productColors = product.querySelectorAll('.product-color__item'); 
        productColors.forEach((productColor) => {
            productColor.classList.remove('color-active');
        });
    };

    let btn = document.querySelector('.btn-scroll__link');
    // При клике прокручиваем на самый верх
    btn.onclick = function (click) {
        click.preventDefault();
        // Вызываем функцию, первый аргумент - отступ, второй - скорость скролла, чем больше значение, тем медленнее скорость прокрутки
        scrollTo(0, 400);
    }

    if (window.screen.width <= 769){
        const cooperation = new Swiper('.mobile-cooperation', {
            loop: true,
            pagination: {
              el: '.cooperation-pagination',
            }
        });
    }

});
     




})(jQuery);


var swiper = new Swiper(".mySwiper", {
    loop: true,
    spaceBetween: 10,
    slidesPerView: 6,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    breakpoints: {
        0:{
            slidesPerView: 2,
        },
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 5,
        },
    },
});
var swiper2 = new Swiper(".mySwiper2", {
    loop: true,
    spaceBetween: 10,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    thumbs: {
        swiper: swiper,
    },
});
